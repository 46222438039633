<template>
  <div class="login-warp">
    <div class="login">
      <div class="logo-info">
        <img class="logo" src="@/assets/logo.png" alt="logo" />
        <span class="name" v-if='!isApp'>友虹文档中台</span>
        <span v-else>友虹文档中台</span>
      </div>

      <div class="login-form">
        <h2 class="title"  v-if='!isApp'>欢迎登录</h2>

        <!-- UserPassword -->
        <el-form ref="form" :rules="rules" :model="loginData" v-if="loginType == 'pwd'">
          <el-form-item prop="username">
            <el-input v-model="loginData.username" prefix-icon="el-icon-user-solid" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginData.password" prefix-icon="el-icon-unlock" placeholder="请输入密码" show-password
              @keyup.enter.native="doLogin"></el-input>
          </el-form-item>
          <el-button class="btn-login" type="primary" :loading="loginState" @click="doLogin">登录</el-button>
        </el-form>

        <!-- Phone -->
        <el-form ref="formPhone" :model="loginPhone" v-if="loginType == 'pho'">
          <el-form-item :error="errors.phone">
            <el-input v-model="loginPhone.phone" prefix-icon="el-icon-user-solid" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
          <el-form-item :error="errors.code">
            <el-input v-model="loginPhone.code" prefix-icon="el-icon-unlock" placeholder="请输入验证码"
              @keyup.enter.native="doLoginPhone">
              <el-button :loading="loading.send" slot="append" @click="sendSMSCode">{{ codeContent }}</el-button>
            </el-input>
          </el-form-item>
          <el-button class="btn-login" type="primary" :loading="loginState" @click="doLoginPhone">登录</el-button>
        </el-form>

        <!-- QRcode -->
        <div class="QRimg" v-if="loginType == 'pqr'">
          <!-- <el-image
              style="width: 100px; height: 100px"
              src="@/assets/u23.png"
              fit="fill"
            ></el-image> -->
          <img style="width: 100px; height: 100px; margin: 15px" src="@/assets/u23.png" alt="" />
          <div>打开手机APP扫码登录</div>
        </div>

        <p class="login-type-box">
          <a v-if="loginType != 'pho' && $isGreadWallVersion" @click="switchLogin('pho')">使用验证码登录</a>
          <a v-if="loginType != 'pwd'" @click="switchLogin('pwd')">使用密码登录</a>
          <!-- <a v-if="loginType != 'pqr'" @click="switchLogin('pqr')">使用二维码登录</a> -->
        </p>
      </div>
    </div>


    <!-- <div class="version">
      Build {{version}} ©友虹科技 2019-2021
    </div> -->
  </div>
</template>
<script>
import { Store } from '@/utils/LocalCache.js'
import { userAPI  } from 'liankong-ui-api'

  export default {
    data() {
      return {
        loginType: "pwd",
        loginData: {
          username: "",
          password: "",
        },
        rules: {
          username: [
            { required: true, message: "请输入登录名", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入登录密码", trigger: "blur" },
            { min: 6, message: "请输入合法的登录密码", trigger: "blur" },
          ],
        },
        version: "2.0.1",
        loginState: false,

        loginPhone: {},
        codeContent: "获取验证码",
        loading: {
          send: false,
        },
        timerRunning: false,
        errors: {
          phone: null,
          code: null,
        },
          docmHeight: document.documentElement.clientHeight,  //默认屏幕高度       
      realHeight:  0, //实时屏幕高度 
      showFooter: true, //显示底部fixed
      };
    },
    computed: {
      isApp(){
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
      }
    },
    watch:{
      realHeight:function() {
          //如果默认高度大于实际高度则影藏，反之就显示
        if(this.docmHeight > this.realHeight){
          this.showFooter = false        
        }else{            
          this.showFooter = true        
        }
      }
    },
    mounted() {
      Store.put('pcLogin','pcLogin')
      if(this.isApp){
        this.loginType  = 'pho' 
      }else{
        this.loginType = 'pwd'
      }
      this.realHeight = document.body.clientHeight    

    },
    methods: {
      getList() {
        // 初始化获取标签列表数据
          this.$store.dispatch('dict/getTagList',{
              pageNum: 1,
              pageSize: 999999
          })
      },
      //密码登录
      doLogin() {
        if(this.isApp){
          setTimeout(() => {
            window.scroll(0, 0)
          })
        }
        
        //表单校验
        this.$refs["form"].validate((valid) => {
          if (valid) {
            //触发登录

            const redirect = this.$route.query.redirect;

            const otherQuery = this.getOtherQuery(this.$route.query);

            this.loginState = true;
            this.$store
              .dispatch("login/loginByPwd", this.loginData)
              .then((data) => {
                if (data == 0) {
                  const loginRoleIsAdmin = this.$store.state.login.roles.some(item=>item.authority=='ROLE_ID_45')
                  if(loginRoleIsAdmin){
                    this.$router.replace({
                      // path: redirect || "/home/user",
                      path:"/managerLange",
                      // query: otherQuery,
                    });
                  } else {
                  //登录成功
                  this.$router.replace({
                    path: redirect || "/",
                      // path:"/",
                      query: otherQuery,
                    });
                  }
                  this.loginState = false;
                  this.getList();
                  this.getPreference()
                  this.$store.dispatch('login/getUserUsedDist')
                   
                }
              })
              .catch((data) => {
                this.$message.error(data.msg);
                this.loginState = false;
              });
          } else {
            return false;
          }
        });
      },

      checkLoginPhone() {
        if (!this.loginPhone.phone) {
          this.errors.phone = "请输入手机号";
          return false;
        } else if (!/^1\d{10}$/.test(this.loginPhone.phone)) {
          this.errors.phone = "请输入正确的手机号";
          return false;
        }

        return true;
      },

      //短信登录
      doLoginPhone() {
        if (this.checkLoginPhone()) {
          if (!this.loginPhone.code) {
            this.errors.code = "请输入验证码";
            return;
          }

          //触发登录
          const redirect = this.$route.query.redirect;
          const otherQuery = this.getOtherQuery(this.$route.query);

          this.loginState = true;
          this.$store
            .dispatch("login/loginBySMS", {
              phoneNumber: this.loginPhone.phone,
              codeNumber: this.loginPhone.code,
            })
            .then((data) => {
              if (data == 0) {
                //登录成功
                const loginRoleIsAdmin = this.$store.state.login.roles.some(item=>item.authority=='ROLE_ID_45')
                  if(loginRoleIsAdmin){
                    this.$router.replace({
                      // path: redirect || "/home/user",
                      path: "/managerLange",
                      // query: otherQuery,
                    });
                  } else {
                  //登录成功
                  this.$router.replace({
                    path: redirect || "/",
                      // path:"/",
                      // query: otherQuery,
                    });
                  }
                this.loginState = false;

                this.getList();
                this.getPreference()
                this.$store.dispatch('login/getUserUsedDist')
              }
            })
            .catch((data) => {
              //登录失败重置倒计时重新登录需做
              this.$message.error(data.message);
              this.loginState = false;
            });
        }
      },

      getOtherQuery(query) {
        return Object.keys(query).reduce((acc, cur) => {
          if (cur !== "redirect") {
            acc[cur] = query[cur];
          }
          return acc;
        }, {});
      },

      switchLogin(type) {
        this.loginType = type;
      },


      //发送验证码
      sendSMSCode() {
        if (this.loading.send || this.timerRunning) return;
        this.errors.phone = null;
        if (this.checkLoginPhone()) {
          this.loading.send = true;
          this.$store
            .dispatch("login/sendLoginCode", {
              phoneNumber: this.loginPhone.phone,
            })
            .then((data) => {
              this.loading.send = false;
              this.countDownChange();
            })
            .catch((err) => {
              this.loading.send = false;
            });
        }
      },
      //倒计时
      countDownChange() {
        let number = 60;
        this.timerRunning = true;
        this.loading.send = false;
        this.codeContent = "60S后重新获取";
        let timer = setInterval(() => {
          if (number > 1) {
            number--;
            this.codeContent = number + "S后重新获取";
            return true;
          } else {
            number = 60;
            clearInterval(timer);
            this.codeContent = "获取验证码";
            this.timerRunning = false;
            return false;
          }
        }, 1000);
      },
      // 获取用户偏好设置
      getPreference(){
        userAPI.getPreference().then(res => {
          if(res.code == 0){
            if(res.data){
              Store.put('myPreference',JSON.parse(res.data))
            }else{
              Store.put('myPreference',null)
            }
            
          }
        })
      }
                  
    },
  };
</script>
<style lang="less" scoped>
  .login-warp {
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/bg-login.jpg");
    background-size: 100% 100%;
    position: relative;

    .version {
      color: #000000;
      position: fixed;
      left: 0;
      bottom: 0;
      font-size: 14px;
    }

    /*表示.login-warp .login*/
    .login {
      display: flex;
      justify-content: space-between;
      width: 800px;
      height: 404px;
      box-shadow: 0 0 20px #515151;
      border-radius: 5px;

      .logo-info {
        width: 400px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;

        .logo {
          height: 150px;
          width: 150px;
        }

        .name {
          margin-top: 50px;
          font-size: 22px;
          letter-spacing: 3px;
        }

        .sub {
          font-size: 12px;
        }
      }

      /*登陆区域*/
      .login-form {
        padding-left: 36px;
        width: 400px;
        background-color: #ffffff;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 40px;
        

        .title {
          text-align: center;
          padding-bottom: 15px;
        }

        .btn-login {
          width: 100%;
          margin-top: 20px;
        }
      }

      /*登陆方式*/
      .login-type-box {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin: 0px;
        margin-top: 15px;

        a {
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .QRimg {
    font-size: 12px;
    height: 164px;
    text-align: center;
  }

  @media (max-width: 500px) {

    .login-warp{
      background: #fff;
      flex-flow: column;
      .login{
        width: 100%;
        display: block;
        box-shadow:none;
        margin-bottom: 100px;
        .login-form{
          padding: 20px!important;
          width: 100%;
        }
        .logo-info{
          width: 100%;
          margin-bottom: 30px;
          text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: #333;
            flex-flow: inherit;
            font-family: cursive;
            font-size: 34px;
            img.logo{
              width: 50px;
              height: auto;
              margin-right: 5px;
            }
        }
      }
    }
  }
</style>